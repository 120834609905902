import { makeStyles } from 'makeStyles';
import { TEXT_GREY_DARK } from 'theme/colors';

export const useStyles = makeStyles()(() => ({
  pageProgress: {
    height: '100vh',
  },
  circularProgress: {
    color: TEXT_GREY_DARK,
  },
}));
