import type React from 'react';

import Grid from '@mui/material/Grid';

import { useStyles } from 'components/progress/styles';
import TMXCircularProgress from 'components/progress/TMXCircularProgress';

const PageProgress: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { classes } = useStyles();
  return (
    <Grid container alignItems="center" className={classes.pageProgress} justifyContent="center">
      <Grid item>
        <TMXCircularProgress size={100} />
      </Grid>
    </Grid>
  );
};

export default PageProgress;
