import type React from 'react';

import CircularProgress, { type CircularProgressProps } from '@mui/material/CircularProgress';

import { useStyles } from 'components/progress/styles';

const TMXCircularProgress: React.FC<React.PropsWithChildren<CircularProgressProps>> = ({ ...props }) => {
  const { classes } = useStyles();
  const thickness = props.thickness || 2;
  return <CircularProgress className={classes.circularProgress} thickness={thickness} {...props} />;
};

export default TMXCircularProgress;
