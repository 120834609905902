import * as Sentry from '@sentry/react';

import { getEnvConfig } from 'config/environment';

export default function sentrySetup() {
  // Sentry fails harmlessly if the DSN is not set, so only set the DSN if we want to enable Sentry for that environment
  Sentry.init({
    dsn: getEnvConfig().REACT_APP_SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    tracesSampleRate: 0.5,
    tracePropagationTargets: ['localhost', /\/graphql/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}
