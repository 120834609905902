import React, { lazy, Suspense } from 'react';

import { createRoot } from 'react-dom/client';

import PageProgress from 'components/progress/PageProgress';
import 'config/dayjs';
import { idiomSetup } from 'config/idiom/idiomSetup';
import sentrySetup from 'config/sentry/sentrySetup';

const App = lazy(() => import('components/App'));

idiomSetup();
sentrySetup();

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Suspense fallback={<PageProgress />}>
      <App />
    </Suspense>
  </React.StrictMode>,
);
