import { isStandalone } from 'utils/environment';

// This header is added to responses in the ingress layer so it needs to be resolved in FE
export default (async () => {
  if (isStandalone()) return;
  try {
    const res = await fetch('/provider');
    (window as any).__provider__ = res?.headers?.['x-clubspark-root-provider-id'];
  } catch (e: any) {
    // eslint-disable-next-line no-console
    console.error(e?.message);
  }
})();
