export const MAIN = '#418fde';
export const MAIN_HOVER = '#427fc0';
export const MAIN_ACTIVE = '#35689d';
export const MAIN_BACKGROUND_FIVE_PERCENT = '#f4f9ff';
export const TEXT_DEFAULT = '#2d3236';
export const WHITE = '#ffffff';
export const TEXT_MAIN = '#3e424e';
export const DISABLED_AND_BORDERS = '#dadce0';
export const BACKGROUND_GREY = '#f6f6f8';
export const TEXT_GREY = '#9da1af';
export const TEXT_GREY_DARK = '#737680';
export const DIVIDER_GREY = '#eaecf1';
export const BORDERS_GREY = '1px solid #eaecf2';
export const ERROR_MAIN = '#800000';
export const TEXT_GREEN = '#169e8f';
export const LIGHT_GREEN = '#07b4a0';
export const DARK_GREEN = '#00806b';
export const LIGHT_RED = 'rgba(245, 154, 35, 0.16078431372549)';
export const MAIN_BACKGROUND_COLOR = 'rgba(246, 246, 248, 1)';
export const ALERT_BACKGROUND = '#fffbed';
export const ALERT_BACKGROUND_BORDER = 'rgba(221, 157, 59, 0.3)';
export const BLUE_BACKGROUND_WRAPPER = '#f4f9ff';
export const BLUE_BACKGROUND_WRAPPER_BORDER = '#9ab3ce';
export const PURPLE_MAIN = '#6e56f2';
export const PURPLE_MAIN_BACKGROUND_FIVE_PERCENT = '#f7f6fe';
export const BORDERS_PURPLE = `1px solid ${PURPLE_MAIN}`;
export const BOX_SHADOW = 'rgba(42, 48, 65, 0.04)';
export const BORDERS_LIGHT_GREY = '#e6e8f0';

export const STATUS_BLUE = '#3920c2';
export const STATUS_BLUE_BG = '#f4f2fe';
export const STATUS_GREEN = '#066d62';
export const STATUS_GREEN_BG = '#f2fcfb';
export const STATUS_RED = '#ad2f3e';
export const STATUS_RED_BG = '#fff5f7';
export const STATUS_ORANGE = '#e95400';
export const STATUS_ORANGE_BG = '#fffbed';
export const STATUS_GREY = '#00143f';
export const STATUS_GREY_BG = '#edf0f6';

export const BACKGROUND_RED = '#d42f42';
export const BACKGROUND_DARK_RED = '#a92332';
export const CANCEL_RED = '#f50057';

export const LOGIN_BLUE = '#00143f';

export const TOOLTIP_BG = '#2a3041';

export const NOTIFICATION_ORANGE = '#dc5100';

export const LIST_HOVER = '#e6e8f0';
export const LIST_SELECTED = '#edf4fc';
export const LIST_SELECTED_TEXT = '#35689d';

export const LIGHT_BLUE2 = '#0357B8';
export const LIGHT_BLUE = '#1a73e8';
