import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { getEnvConfig } from 'config/environment';
import { defaultIdiom } from 'config/idiom/defaultIdiom';

export type SupportedLanguages = 'en' | 'fr' | 'sl' | 'sr';

export function idiomSetup() {
  const isLeagueDesk = window.location.pathname.includes('/leagues');

  const idiomDefault = defaultIdiom();

  const lng = (getEnvConfig().REACT_APP_LANGUAGE || 'en').toLowerCase().trim();

  i18n
    .use(detector)
    .use(initReactI18next)
    .init({
      detection: {
        caches: [],
        ...(isLeagueDesk && {
          convertDetectedLanguage: (lng) => {
            const languageCode = lng.includes('-') ? lng.split('-')[0] : lng;
            return `${languageCode}Leagues`;
          },
        }),
      },
      ...((getEnvConfig().REACT_APP_MULTI_LANGUAGE || '').trim() !== 'true' && { lng }),
      resources: idiomDefault,
      defaultNS: 'translation',
      ns: ['translation'],
      fallbackLng: (lng) => {
        const originalLangCode = lng.includes('Leagues') ? lng.split('Leagues')[0] : lng;
        return [originalLangCode, 'en'];
      },
      interpolation: {
        escapeValue: false,
      },
      react: {
        useSuspense: true,
      },
    });
}
