import enLeagues from './en-leagues.json';
import en from './en.json';
import frLeagues from './fr-leagues.json';
import fr from './fr.json';
import slLeagues from './sl-leagues.json';
import sl from './sl.json';
import srLeagues from './sr-leagues.json';
import sr from './sr.json';

export const defaultIdiom = () => ({
  en,
  fr,
  sl,
  sr,
  enLeagues,
  frLeagues,
  slLeagues,
  srLeagues,
});
